import { MyAbout } from "../components/About"


export default function AboutPage() {
  return (
    <div className="transition">
      <MyAbout/>
    </div>
  )
}
